@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
      
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.formbold-main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formbold-form-wrapper {
  margin: 0 auto;
  max-width: 550px;
  width: 100%;
  background: transparent;
}

.formbold-input-flex {
  display: flex;
  gap: 20px;
  margin: 1px 0; /* Adjust margin to bring the fields closer */
}

.formbold-input-flex > div {
  flex: 1;
}

.formbold-input-column {
  display: flex;
  flex-direction: column-reverse;
}

.formbold-textarea {
  margin-top: 20px;
  display: flex;
  flex-direction: column-reverse;
}

.formbold-form-input {
  border-radius: 15px;
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 3px solid #067e56;
  background: #FFFFFF;
  font-weight: 500;
  font-size: 16px;
  color: #07074D;
  outline: none;
  resize: none;
}

.formbold-form-input::placeholder {
  color: #536387;
}

.formbold-form-label {
  color: black;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: block;
  margin-bottom: 18px;
  font-weight: bold;
}

.formbold-form-input:focus + .formbold-form-label {
  color: white;
  font-weight: bold;
}